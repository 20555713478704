<template>
    <div>
        <fieldset>
            <Phone
                v-model="form.phone"
                :error="formErrors.phone"
                :disabled="!!user.contactData.id"
                @focus="phoneFocus"
                title="Номер телефона"
                autofocus
            />
            <Field
                v-model="form.email"
                :error="formErrors.email"
                @focus="emailFocus"
                title="E-mail"
            />
        </fieldset>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Phone from '@/components/form/field/Phone'

import validationMixin from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import stat from '@/api/stat'

export default {
    name: 'ContactInformation',
    mixins: [validationMixin],
    data() {
        // @TODO подумать как избавиться от этих переменных
        return {
            emailFocused: false,
            phoneFocused: false,
            form: {
                phone: '',
                email: ''
            },
            formRules: {
                phone: [Validation.REQUIRED, Validation.PHONE, Validation.MOBILE],
                email: [Validation.EMAIL]
            }
        }
    },
    created() {
        const {
            phone,
            email
        } = this.user.contactData

        this.form.phone = phone
        this.form.email = email

        if (this.form.phone || this.user.contactData.id)
            return

        this.form.phone = this.$route.query?.phone || '';
    },
    computed: {
        ...mapGetters({
            user: 'application/user',
        })
    },
    methods: {
        phoneFocus() {
            if (this.phoneFocused)
                return

            this.phoneFocused = true;
            stat('phone')
        },
        emailFocus() {
            if (this.emailFocused)
                return

            this.emailFocused = true;
            stat('email')
        }
    },
    components: {
        Phone,
    },
};
</script>
